<template>
  <div class="ma-0 pa-0">
    <v-container
      v-if="pageLoading"
      fluid
      class="d-flex justify-center align-center elevation-loading-container"
    >
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-container>

    <v-container v-else class="pa-0 ma-0 pr-12">
      <v-row align="center" justify="start">
        <v-col cols="5">
          <div class="textOrange--text display-1 py-2">Elevation Module</div>
          <div class="subtitle-1 mt-2 font-weight-light">
            Elevation Module creates and streamline training opportunities,
            surveys, feedback loops and educational opportunities the encourage
            growth and elevate your workforce to increase productivity
          </div>
          <div class="subtitle-1 text-uppercase mt-6 mb-4">Steps</div>
          <v-stepper v-model="steps" vertical class="stepper-contianer-for-elevation pa-0 ma-0">
            <v-stepper-step class="ma-0 px-0 py-1" step=" " icon-state :complete="steps > 1">
              <label v-if="steps < 2">Activate Welcome Card</label>
              <label v-else class="primary--text">Activate Welcome Card</label>
            </v-stepper-step>
            <v-divider class="mx-3 primary" style="height:25px" vertical></v-divider>
            <v-stepper-step :complete="steps > 2" class="ma-0 px-0 py-1" step=" ">
              <label v-if="steps < 3">Create Training</label>
              <label v-else class="primary--text">Create Training</label>
            </v-stepper-step>
          </v-stepper>
        </v-col>
        <v-col cols="6" class="px-6">
          <template v-for="(item, index) in cards">
            <div class="ma-2 pb-2" :key="index">
              <bz-card
                :disabled="item.disabled"
                :title="item.title"
                :subtitle="item.subtitle"
                :isActive="item.isActive"
                :link="item.link"
              />
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog content-class="roleDialog" persistent v-model="showRolesDialog">
      <p class="ma-2 mx-4 pa-0 pt-3">Choose Role</p>
      <v-row class="ma-0 pa-0 pb-3">
        <v-col class="mx-2">
          <v-card height="211" width="320" class="roleCard ma-0 pa-0">
            <div class="d-flex flex-row" style="height:100%">
              <div class="textOrange divBox" width="20" height="211" style></div>
              <div class="d-flex flex-column justify-center px-12 pt-4">
                <div class="lowHeight">
                  <p class="ma-0 pa-0 font-weight-light">Elevation Module</p>
                  <p class="textOrange--text font-weight-bold pa-0 ma-0">ROLE: Account Owner</p>
                </div>
                <v-btn
                  width="171"
                  depressed
                  class="textOrange textLight--text align-center text-capitalize mt-8 title font-weight-light"
                  @click="forAccountOwner"
                >Select</v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col class="mx-2">
          <v-card height="211" width="320" class="roleCard ma-0 pa-0">
            <div class="d-flex flex-row" style="height:100%">
              <div class="primary divBox" width="20" height="211" style></div>
              <div class="d-flex flex-column justify-center px-12 pt-4">
                <div class="lowHeight">
                  <p class="ma-0 pa-0 font-weight-light">Elevation Module</p>
                  <p class="primary--text font-weight-bold pa-0 ma-0">ROLE: Evaluator</p>
                </div>
                <v-btn
                  width="171"
                  depressed
                  class="primary textLight--text align-center text-capitalize mt-8 title font-weight-light"
                  :to="{name: 'evaluator-home'}"
                >Select</v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
  <!-- <v-container v-else fluid class="elevation-container mr-0 my-0 pa-0">
    <v-row>
      <v-col sm="12" cols="12" lg="5">
        <h1 class="textOrange--text display-1 py-2">Elevation Module</h1>

        <div class="text-container-for-elevation">
          <p>
            Elevation Module creates and streamline training opportunities,
            surveys, feedback loops and educational opportunities the encourage
            growth and elevate your workforce to increase productivity
          </p>
        </div>

        <div>
          <div>
            <h1 class="textDark--text headline pb-2">Steps</h1>
          </div>
          <v-stepper v-model="steps" vertical class="stepper-contianer-for-elevation pa-0 ma-0">
            <v-stepper-step class="ma-0 px-0 py-2" step=" " icon-state :complete="steps > 1">
              <label v-if="steps < 2">Activate Welcome Card</label>
              <label v-else class="primary--text">Activate Welcome Card</label>
            </v-stepper-step>
            <v-stepper-step :complete="steps > 2" class="ma-0 px-0 py-2" step=" ">
              <label v-if="steps < 3">Create Training</label>
              <label v-else class="primary--text">Create Training</label>
            </v-stepper-step>
          </v-stepper>
        </div>
      </v-col>
      <v-col class="ml-12" lg="6" sm="12" cols="12">
        <template v-for="(item, index) in cards">
          <div class="ma-2 pb-2" :key="index">
            <bz-card
              :disabled="item.disabled"
              :title="item.title"
              :subtitle="item.subtitle"
              :isActive="item.isActive"
              :link="item.link"
            />
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>-->
</template>

<script>
import BzCard from "../../../components/BzCard";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { roles } from "@/shared/constants/roles";

export default {
  name: "elevation-module",
  components: { BzCard },
  computed: {
    ...mapFields("admin", ["accountModules", "elevationModule"]),
    ...mapFields("auth", ["currentUser", "accountRoles"])
  },
  async created() {
    if (this.accountRoles.includes(roles.COURSE_EVALUATOR)) {
      this.showRolesDialog = true;
    } else {
      await this.forAccountOwner();
    }
  },
  methods: {
    ...mapActions("admin", {
      getAccountModules: "getAccountModules"
    }),
    ...mapActions("admin", {
      getElevationModules: "getElevationModules"
    }),
    async forAccountOwner() {
      this.showRolesDialog = false;
      this.pageLoading = true;
      const accountModules = await this.getAccountModules(
        this.currentUser.accounts[0]
      );
      let elevationModule;
      for (var modle in accountModules) {
        if (accountModules[modle].moduleName == "Elevation Module") {
          elevationModule = accountModules[modle];
          break;
        }
      }
      await this.checkTheActivation(elevationModule);
      this.pageLoading = false;
    },
    checkTheActivation(moduleData) {
      if (moduleData.welcomeCardId !== null) {
        this.cards[1].disabled = false;
        this.cards[0].isActive = true;
        this.moveStepper();
      } else {
        this.cards[1].disabled = true;
        this.cards[1].isActive = false;
        this.cards[0].isActive = false;
      }
      if (moduleData.trainingCardId.length > 0) {
        this.cards[1].disabled = false;
        this.cards[1].isActive = true;
        this.moveStepper();
      }
      // this.cards[0].isActive && this.moveStepper();
      // this.cards[1].isActive && this.moveStepper();
    },
    async checkActivation() {
      let ElevationIds = {
        welcomeCardId: null,
        trainingCardId: null
      };

      const ElevationData = await this.getElevationModules(
        this.currentUser.accounts[0]
      );

      if (ElevationData.length > 0) {
        if (
          ElevationData[0].configurationId ===
          this.elevationModule.configurationsIds[0]
        ) {
          ElevationIds.welcomeCardId = this.elevationModule.configurationsIds[0];
        }
        if (
          ElevationData[1] &&
          ElevationData[1].configurationId ===
            this.elevationModule.configurationsIds[1]
        ) {
          ElevationIds.trainingCardId = this.elevationModule.configurationsIds[1];
        }
      }

      if (ElevationIds) {
        ElevationIds.welcomeCardId && (this.cards[0].isActive = true);
        ElevationIds.welcomeCardId
          ? (this.cards[1].disabled = false)
          : (this.cards[1].disabled = true);
        ElevationIds.trainingCardId && (this.cards[1].isActive = true);

        this.cards[0].isActive && this.moveStepper();
        this.cards[1].isActive && this.moveStepper();
      }
    },
    moveStepper() {
      // console.log("Moving");
      this.steps = this.steps + 1;
    }
  },
  data() {
    return {
      disabled: true,
      showRolesDialog: true,
      pageLoading: true,
      steps: 1,
      cards: [
        {
          title: "Welcome Card",
          isActive: false,
          link: "elevation-welcome-card",
          subtitle:
            "Serves as an introductory tool that provides your team with relevant information"
        },
        {
          title: "Training",
          isActive: false,
          link: "elevation-training-card",
          subtitle: "Create customized Training Opportunities for your team.",
          disabled: true,
          to: {
            name: "elevation-training-card",
            params: {
              id: ""
            }
          }
        }
      ],
      elevationLinks: [
        {
          text: "Modules",
          disabled: false,
          to: {
            name: "modules"
          }
        },
        {
          text: "Elevation Module",
          disabled: true
        }
      ]
    };
  }
};
</script>

<style>
.text-container-for-elevation {
  width: 504px;
}
.stepper-contianer-for-elevation {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
.elevation-container {
  width: 1400px;
}

.elevation-loading-container {
  margin-top: 250px;
}
.divBox {
  width: 20px;
  height: 211px;
  clear: both;
  border-radius: 6px 0px 0px 6px;
}
.roleCard {
  box-shadow: 0px 0px 25px #0000004f;
}
.roleDialog {
  width: 723px;
  overflow-y: inherit;
  background: white;
}
.lowHeight {
  line-height: 1;
}
</style>
